import React from "react";
import { Link } from "react-router-dom";

const DigitalMarketing = () => {
  return (
    <>
      <section className="breadcrumbs">
        <div className="container">
          <div className="d-flex justify-content-between align-items-center">
            <ol>
              <li>
                <Link to="/">Home</Link>
              </li>
              <li>
                <Link to="/#courses">Courses</Link>
              </li>
              <li>Digital Marketing</li>
            </ol>
          </div>
        </div>
      </section>
      <section id="portfolio-details" className="course-details">
        <div className="container">
          <h1>Digital Marketing</h1>
          <br />
          <div className="row">
            <img
              className="course-details-image col-md-4"
              src="/assets/img/courses/5.png"
              alt="portfolio-img"
            />
            <div className="course-details-wrapper col-md-6">
              <p>
                The internet landscape might seem intimidating, but effective
                digital marketing can be an enormous boon for business. With
                careful strategy and implementation, the benefits of digital
                marketing on business include the expansion of the audience,
                meeting target customers where they’re spending their time, and
                achieving this for a far more affordable price than traditional
                marketing methods allow. Keeping in mind the demand for Digital
                Marketing, we have designed our course in such a way that anyone
                can do this course and start their careers in the field of
                Digital Marketing.
              </p>

              <br />
              <h6>Price:</h6>
              <p>3000 + GST</p>
              <h6>Duration:</h6>
              <p>1.5 Month - 45 Hours</p>
              <p>Practical Based Learning</p>
            </div>
          </div>

          <div className="course-syllabus">
            <h6>Course Syllabus :</h6>
            <ol>
              <li>Introduction to Digital Marketing </li>
              <li>Search Engine Optimization </li>
              <li>Search engine marketing </li>
              <li>Social media Marketing </li>
              <li>Content Marketing & Strategy </li>
              <li>Pay per click program </li>
              <li>Mobile Marketing </li>
              <li>Email Marketing </li>
              <li>Digital Analytics </li>
            </ol>
          </div>
          <br />
          <div className="course-syllabus">
            <h6>Practicals</h6>
            <ol>
              <li>Search Engine Optimization </li>
              <li>Search engine marketing </li>
              <li>Social media Marketing </li>
              <li>Content Marketing & Strategy </li>
              <li>Pay per click program </li>
              <li>Mobile Marketing </li>
              <li>Email Marketing </li>
              <li>Digital Analytics </li>
            </ol>
          </div>
        </div>
      </section>
    </>
  );
};
export default DigitalMarketing;
