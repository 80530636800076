import React from "react";

const Team = () => {
  return (
    <>
      <section id="team" className="team section-bg">
        <div className="container">
          <div className="section-title">
            <h2 data-aos="fade-in">Team</h2>
            <span data-aos="fade-in"></span>
          </div>

          <div className="row">
            <div className=" col-md-4 offset-md-2">
              <div className="member" data-aos="fade-up">
                <div className="pic">
                  <img src="/assets/img/team/founder.jpg" alt="" />
                </div>
                <h4>Prahlad Tripathi</h4>
                <span>Co-founder and Managing Director</span>
                <div className="social">
                  <a
                    href="https://www.linkedin.com/in/prahlad-tripathi-92822214/ "
                    target="_blank"
                  >
                    <i className="bi bi-linkedin"></i>
                  </a>
                </div>
              </div>
            </div>

            <div className="col-md-4 ">
              <div className="member" data-aos="fade-up" data-aos-delay="100">
                <div className="pic">
                  <img src="/assets/img/team/co-founder.jpg" alt="" />
                </div>
                <h4>Mridula Tripathi</h4>
                <span>CEO and Founder</span>
                <div className="social">
                  <a
                    href=" https://www.linkedin.com/in/mridula-tripathi-2a3772ab/ "
                    target="_blank"
                  >
                    <i className="bi bi-linkedin"></i>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Team;
