import React from "react";

export const Contact = () => {
  return (
    <>
      <section id="contact" className="contact section-bg">
        <div className="container" data-aos="fade-up">
          <div className="section-title">
            <h2>Contact</h2>
          </div>

          <div className="row ">
            <div className="col-lg-6">
              <div className="row">
                <div
                  className="info-box"
                  data-aos="fade-up"
                  data-aos-delay="100"
                >
                  <i className="bx bx-phone-call"></i>
                  <h3>Call Us</h3>
                  <span>(+91) 809 7595 666</span>
                </div>
              </div>
            </div>
            <div className="col-lg-6  ">
              <div className="info-box" data-aos="fade-up" data-aos-delay="100">
                <i className="bx bx-envelope"></i>
                <h3>Email Us</h3>
                <span>hello@tradeschool.co.in</span>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
export default Contact;
