import React from "react";

const Hero = () => {
  return (
    <>
      <section id="hero">
        <div className="container">
          <div className="row d-flex align-items-center">
            <div
              className="col-sm-12 col-lg-6 py-5 py-lg-0 order-2 order-lg-1"
              data-aos="fade-right"
            >
              <h1>TradeSchool</h1>
              <h2>Learn To Earn Any Time, AnyWhere</h2>
              <div className="left-align">
                <a
                  href="https://apps.apple.com/in/app/trade-school/id1606878686"
                  target="_blank"
                  className="btn-get-started scrollto mx-2 mb-3"
                >
                  <i className="fab fa-apple mx-2"></i>App Store
                </a>
                <a
                  href="https://play.google.com/store/apps/details?id=in.co.tradeschool.app7"
                  target="_blank"
                  className="btn-get-started  scrollto"
                >
                  <i className="fab fa-google-play mx-2"></i>Google Play
                </a>
              </div>
            </div>
            <div
              className="col-sm-12 col-lg-6 order-1 order-lg-2 hero-img"
              data-aos="fade-left"
            >
              <img
                src="/assets/img/hero_img.png"
                className="img-fluid"
                alt=""
              />
            </div>
          </div>
        </div>
        <a
          href="https://wa.me/+918097595666"
          target="_blank"
          className="whats-app"
        >
          {/* <i className="fa-brands fa-whatsapp my-float"></i> */}
          <i className="bx bxl-whatsapp fs-xl my-float"></i>
        </a>
      </section>
    </>
  );
};

export default Hero;
