import Footer from "components/Footer";
import Header from "components/Header";
import React from "react";
import { useNavigate } from "react-router-dom";

const NotFound = () => {
  const redirect = useNavigate();
  const style = {
    position: "fixed",
    left: "0",
    bottom: "0",
    width: "100%",
    color: "white",
  };
  return (
    <>
      <Header />
      <div className="container-fluid " style={{ position: "relative" }}>
        <div className="row d-flex">
          <div
            className="text-center  flex-column align-item-center justify-center-center "
            style={{
              marginTop: "5%",
            }}
          >
            <h1>404</h1>
            <span>Page Not Found</span>
            <br />
            <button
              type="button"
              className="btn  bg-default mt-3"
              onClick={() => redirect("/")}
              style={{
                borderRadius: "50px",
                padding: "4px 20px",
                backgroundColor: "#213b52",
                color: "white",
              }}
            >
              Go Home
            </button>
          </div>
        </div>
      </div>
      <div className="footer" style={style}>
        <Footer></Footer>
      </div>
    </>
  );
};

export default NotFound;
