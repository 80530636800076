import React from "react";

import { Link } from "react-router-dom";

const Courses = () => {
  return (
    <>
      <section id="courses" className="courses section-bg mx-auto">
        <div className="container">
          <div className="section-title">
            <h2 data-aos="fade-in">Courses Offered</h2>
          </div>
          <div className="row">
            <div className="col-md-4 col-sm-12  d-flex " data-aos="fade-right">
              <Link to="/courses/financial-literacy">
                <div className="card">
                  <div className="card-img">
                    <img src="/assets/img/courses/1.png" alt="..." />
                  </div>
                  <div className="card-body">
                    <h5 className="card-title">Financial Literacy</h5>
                  </div>
                </div>
              </Link>
            </div>
            <div className="col-md-4 col-sm-12  d-flex" data-aos="fade-right">
              <Link to="/courses/mutual-funds">
                <div className="card">
                  <div className="card-img">
                    <img src="/assets/img/courses/2.png" alt="..." />
                  </div>
                  <div className="card-body">
                    <h5 className="card-title">Mutual Funds</h5>
                  </div>
                </div>
              </Link>
            </div>
            <div className="col-md-4 col-sm-12  d-flex" data-aos="fade-right">
              <Link to="/courses/equity-derivatives">
                <div className="card">
                  <div className="card-img">
                    <img src="/assets/img/courses/3.png" alt="..." />
                  </div>
                  <div className="card-body card-upcoming">
                    <h5 className="card-title">Equity Derivatives </h5>
                  </div>
                </div>
              </Link>
            </div>
            <div className="col-md-4 col-sm-12  d-flex" data-aos="fade-right">
              <Link to="/courses/research-analysis">
                <div className="card">
                  <div className="card-img">
                    <img src="/assets/img/courses/14.png" alt="..." />
                  </div>
                  <div className="card-body card-upcoming">
                    <h5 className="card-title">Research Analysis </h5>
                  </div>
                </div>
              </Link>
            </div>
            <div className="col-md-4 col-sm-12  d-flex" data-aos="fade-right">
              <Link to="/courses/digital-marketing">
                <div className="card">
                  <div className="card-img">
                    <img src="/assets/img/courses/5.png" alt="..." />
                  </div>
                  <div className="card-body card-upcoming">
                    <h5 className="card-title">Digital Marketing</h5>
                  </div>
                </div>
              </Link>
            </div>
            <div className="col-md-4 col-sm-12  d-flex" data-aos="fade-right">
              <Link to="/courses/lets-start-up">
                <div className="card">
                  <div className="card-img">
                    <img src="/assets/img/courses/6.png" alt="..." />
                  </div>
                  <div className="card-body card-upcoming">
                    <h5 className="card-title">Let's Start Up</h5>
                  </div>
                </div>
              </Link>
            </div>
            <div className="col-md-4 col-sm-12  d-flex" data-aos="fade-right">
              <Link to="/courses/data-science">
                <div className="card">
                  <div className="card-img">
                    <img src="/assets/img/courses/7.png" alt="..." />
                  </div>
                  <div className="card-body">
                    <h5 className="card-title">Data Science</h5>
                  </div>
                </div>
              </Link>
            </div>
            <div className="col-md-4 col-sm-12  d-flex " data-aos="fade-right">
              <Link to="/courses/cyber-security">
                <div className="card">
                  <div className="card-img">
                    <img src="/assets/img/courses/8.png" alt="..." />
                  </div>
                  <div className="card-body">
                    <h5 className="card-title">Cyber Security</h5>
                  </div>
                </div>
              </Link>
            </div>
            <div className="col-md-4 col-sm-12  d-flex" data-aos="fade-right">
              <Link to="/courses/big-data">
                <div className="card">
                  <div className="card-img">
                    <img src="/assets/img/courses/9.png" alt="..." />
                  </div>
                  <div className="card-body card-upcoming">
                    <h5 className="card-title">Big Data </h5>
                  </div>
                </div>
              </Link>
            </div>
            <div className="col-md-4 col-sm-12 d-flex" data-aos="fade-right">
              <Link to="/courses/data-visualization">
                <div className="card">
                  <div className="card-img">
                    <img src="/assets/img/courses/10.png" alt="..." />
                  </div>
                  <div className="card-body card-upcoming">
                    <h5 className="card-title">Data Visualization</h5>
                  </div>
                </div>
              </Link>
            </div>
            <div className="col-md-4 col-sm-12  d-flex" data-aos="fade-right">
              <Link to="/courses/python-programming">
                <div className="card">
                  <div className="card-img">
                    <img src="/assets/img/courses/11.png" alt="..." />
                  </div>
                  <div className="card-body">
                    <h5 className="card-title">Python Programming</h5>
                  </div>
                </div>
              </Link>
            </div>

            <div className="col-md-4 col-sm-12  d-flex" data-aos="fade-right">
              <Link to="/courses/drone-pilot">
                <div className="card">
                  <div className="card-img">
                    <img src="/assets/img/courses/12.png" alt="..." />
                  </div>
                  <div className="card-body">
                    <h5 className="card-title">Drone Pilot</h5>
                  </div>
                </div>
              </Link>
            </div>
          </div>
        </div>
      </section>
      {/* <section className="courses section-bg mx-auto">
        <div className="container">
          <div className="section-title text-center">
            <h2>Upcoming Courses</h2>
          </div>
        </div>
      </section> */}
    </>
  );
};

export default Courses;
