import React, { useEffect } from "react";
// hello world
const Testimonials = () => {
  useEffect(() => {
    $(document).ready(function () {
      $(".owl-carousel.testimonial-carousel").owlCarousel({
        nav: true,
        navText: [
          `<i className="fa-solid fa-caret-left"></i>`,
          `<i className="fa-solid fa-caret-right"></i>`,
        ],
        dots: false,
        autoplay: true,
        autoplayTimeout: 2000,
        responsive: {
          0: {
            items: 1,
            loop: true,
          },
          750: {
            items: 3,
            loop: true,
          },
        },
      });
      $(".moreless-button").unbind("click");
      $(".moreless-button").click(function () {
        console.log("moretext span", $(this).children(".moretext"));
        $(this).parent().children(".moretext").slideToggle();
        if ($(this).text() == "read more") {
          $(this).text("read less");
        } else {
          $(this).text("read more");
        }
      });
    });
  }, []);

  return (
    <>
      <section id="testimonials" className="testimonials section-bg mx-auto">
        <div className="container">
          <div className="section-title">
            <h2 data-aos="fade-in">Testimonials</h2>
          </div>

          <div className="owl-carousel testimonial-carousel ">
            <div className="single-testimonial">
              <div className="testimonials-wrapper">
                <h4>
                  This course very helpful, which improved my financial
                  knowledge and taught me to deal in securities market and
                  different type
                  <span className="moretext">
                    of investment, it's important in today's world.
                  </span>
                  <a className="moreless-button">read more</a>
                </h4>
                <div className="testimonials-blob"></div>
                <div className="testimonials-img">
                  <img
                    src="/assets/img/testimonials/khayati.png"
                    className="img-100"
                  />
                </div>
                <div className="testimonials-person-info">
                  <p>
                    <b>Khayati kanani</b>
                    <br />
                    KES College of Arts and Commerce
                  </p>
                </div>
              </div>
            </div>

            <div className="single-testimonial">
              <div className="testimonials-wrapper">
                <h4>
                  Trade school has been an excellent learning platform. The
                  courses available are taught in a way that not only helps you
                  to enhance your
                  <span className="moretext">
                    knowledge but keeps you interested just as well. The live
                    lectures have been extremely helpful in understanding
                    financial markets practically. All the teachers have
                    distinctive yet amazing teaching styles that simplify
                    trading and investing.
                  </span>
                  <a className="moreless-button">read more</a>
                </h4>
                <div className="testimonials-blob"></div>
                <div className="testimonials-img">
                  <img
                    src="/assets/img/testimonials/testimonial_avtar.png"
                    className="img-100"
                  />
                </div>
                <div className="testimonials-person-info">
                  <p>
                    <b>Shruti Kukreja</b>
                    <br />
                    Lad College
                  </p>
                </div>
              </div>
            </div>

            <div className="single-testimonial">
              <div className="testimonials-wrapper">
                <h4>
                  This is one of the best platforms to learn about the stock
                  market which includes basic, fundamental and Technical.
                  <span className="moretext">
                    It also gives you E-books, and videos access for a lifetime
                    .This knowledge helps us to trade or invest in any type of
                    securities and one can opt for this course to become
                    financially independent.
                  </span>
                  <a className="moreless-button">read more</a>
                </h4>
                <div className="testimonials-blob"></div>
                <div className="testimonials-img ">
                  <img
                    src="/assets/img/testimonials/testimonial_avtar.png"
                    className="img-100"
                  />
                </div>
                <div className="testimonials-person-info">
                  <p>
                    <b>Neha Kulshreshtha</b>
                    <br />
                  </p>
                </div>
              </div>
            </div>

            <div className="single-testimonial">
              <div className="testimonials-wrapper ">
                <h4>
                  Trade school is a fantastic platform for all people who wish
                  to Earn money from investing. Teachers taught us from basic
                  level to advanced level in both
                  <span className="moretext">
                    fundamental as well as technical. Professors are well
                    experienced and friendly so that everyone can clear their
                    doubts during the session. I recommend everyone to invest
                    money in learning because it is far better to lose money on
                    tips or recommendation
                  </span>
                  <a className="moreless-button">read more</a>
                </h4>
                <div className="testimonials-blob"></div>
                <div className="testimonials-img">
                  <img
                    src="/assets/img/testimonials/testimonial_avtar.png"
                    className="img-100"
                  />
                </div>
                <div className="testimonials-person-info">
                  <p>
                    <b>Dhruvil Mistry</b>
                    <br />
                  </p>
                </div>
              </div>
            </div>

            <div className="single-testimonial">
              <div className="testimonials-wrapper">
                <h4>
                  Trade school is a good platform for the stock market beginner.
                  It gives a very good introduction to the stock market.
                  <span className="moretext">
                    It has well covered Fundamental Technical analysis and also
                    teaches money management. Trade school Trainer has
                    experience and good Knowledge. He teaches the easy way and
                    best method to use. The best part is Trade in live market
                    classes.
                  </span>
                  <a className="moreless-button">read more</a>
                </h4>
                <div className="testimonials-blob"></div>
                <div className="testimonials-img">
                  <img
                    src="/assets/img/testimonials/testimonial_avtar.png"
                    className="img-100"
                  />
                </div>
                <div className="testimonials-person-info">
                  <p>
                    <b>Laxman Machhi</b>
                    <br />
                  </p>
                </div>
              </div>
            </div>

            <div className="single-testimonial">
              <div className="testimonials-wrapper">
                <h4>
                  Trade School is the best platform to understand the importance
                  of Financial Literacy. It has taught us from Basic to advance
                  with
                  <span className="moretext">
                    practical knowledge. Trade School is the lifetime
                    opportunity that everyone should grab. Thank you Trade
                    School and team.
                  </span>
                  <a className="moreless-button">read more</a>
                </h4>
                <div className="testimonials-blob"></div>
                <div className="testimonials-img">
                  <img
                    src="/assets/img/testimonials/ekta.png"
                    className="img-100"
                  />
                </div>
                <div className="testimonials-person-info">
                  <p>
                    <b>Ekta Laddha</b>
                    <br />
                    Indira Institute of Management, pune
                  </p>
                </div>
              </div>
            </div>

            <div className="single-testimonial">
              <div className="testimonials-wrapper">
                <h4>
                  Trade school is a financial Market Course. In financial market
                  course we have study (a). Beginners module (b) fundamental
                  analysis (c) Technical analysis
                  <span className="moretext">
                    (d) Trading psychology (e) Risk management (f) money
                    management (g) virtual Trading. Includes practical training
                    etc. In this course I Learned lot of knowledge of market,
                    Investments Trading etc. And also i learned How to speak
                    English, how to give you interview, how to develop your
                    personality, how to identify people and how to deal with
                    them etc. More update about market and there sales,
                    investment.
                  </span>
                  <a className="moreless-button">read more</a>
                </h4>
                <div className="testimonials-blob"></div>
                <div className="testimonials-img ">
                  <img
                    src="/assets/img/testimonials/prachi.png"
                    className="img-100"
                  />
                </div>
                <div className="testimonials-person-info">
                  <p>
                    <b>Prachi dongre</b>
                    <br />
                    Dayanand Arya Kanya Mahavidyalaya, Nagpur
                  </p>
                </div>
              </div>
            </div>

            <div className="single-testimonial">
              <div className="testimonials-wrapper">
                <h4>
                  I have learned about investment. the effect of money on import
                  or export, global market, etc. I have learned how to analyze
                  companies trading net profit.
                  <span className="moretext">
                    One of the best things about Trade School is along with the
                    courses they provide free personality development,
                    internship opportunity, and placement assistance.
                  </span>
                  <a className="moreless-button">read more</a>
                </h4>
                <div className="testimonials-blob"></div>
                <div className="testimonials-img ">
                  <img
                    src="/assets/img/testimonials/gurpreet.png"
                    className="img-100"
                  />
                </div>
                <div className="testimonials-person-info">
                  <p>
                    <b>Gurpreet kaur matharu</b>
                    <br />
                    Dayanand Arya Kanya Mahavidyalaya, Nagpur
                  </p>
                </div>
              </div>
            </div>

            <div className="single-testimonial">
              <div className="testimonials-wrapper">
                <h4>
                  Trade school is the best course in Nagpur. We get more
                  knowledge about trading, and investment, Indian market
                  updates. Thank you trade school
                </h4>
                <div className="testimonials-blob"></div>
                <div className="testimonials-img ">
                  <img
                    src="/assets/img/testimonials/sukhbir.png"
                    className="img-100"
                  />
                </div>
                <div className="testimonials-person-info">
                  <p>
                    <b>Sukhbir kaur matharu</b>
                    <br />
                    Dayanand Arya Kanya Mahavidyalaya, Nagpur
                  </p>
                </div>
              </div>
            </div>

            <div className="single-testimonial">
              <div className="testimonials-wrapper">
                <h4>
                  Trade School provides E-books , live sessions, Practical
                  learning and many more things. So, Trade School courses are
                  must to enroll.
                  <span className="moretext">
                    Finally, I wanna say that Trade School provides the best
                    course for improving your knowledge about financial
                    literacy.
                  </span>
                  <a className="moreless-button">read more</a>
                </h4>
                <div className="testimonials-blob"></div>
                <div className="testimonials-img ">
                  <img
                    src="/assets/img/testimonials/anjali.png"
                    className="img-100"
                  />
                </div>
                <div className="testimonials-person-info">
                  <p>
                    <b>Anjali Sanjay bagde</b>
                    <br />
                    Dayanand Arya Kanya Mahavidyalaya, Nagpur
                  </p>
                </div>
              </div>
            </div>

            <div className="single-testimonial">
              <div className="testimonials-wrapper">
                <h4>
                  This is a great platform to learn financial literacy course,
                  with no prior knowledge of stock market.This course helped me
                  to understand all
                  <span className="moretext">
                    me basic concept and enhance my interest in this field. And
                    all the teachers are very frank, kind, helpful.
                  </span>
                  <a className="moreless-button">read more</a>
                </h4>
                <div className="testimonials-blob"></div>
                <div className="testimonials-img">
                  <img
                    src="/assets/img/testimonials/somya.png"
                    className="img-100"
                  />
                </div>
                <div className="testimonials-person-info">
                  <p>
                    <b>Somya Ramkumar kaur</b>
                    <br />
                    Dayanand Arya Kanya Mahavidyalaya, Nagpur
                  </p>
                </div>
              </div>
            </div>

            <div className="single-testimonial">
              <div className="testimonials-wrapper">
                <h4>
                  Trade School is the best platform for becoming financially
                  literate. Trade school provides good knowledge not only of the
                  Indian financial market
                  <span className="moretext">
                    but also of the International financial market. The teaching
                    faculty of Trade School has good knowledge about Finance and
                    the Stock market and teach the students very effectively.
                    The best part of learning financial literacy from Trade
                    School is that it teaches Theoretical+Practical
                  </span>
                  <a className="moreless-button">read more</a>
                </h4>
                <div className="testimonials-blob"></div>
                <div className="testimonials-img">
                  <img
                    src="/assets/img/testimonials/poonam1.png"
                    className="img-100"
                  />
                </div>
                <div className="testimonials-person-info">
                  <p>
                    <b>Poonam Sharma</b>
                    <br />
                    Jankidevi Bajaj Institute of Management Studies
                  </p>
                </div>
              </div>
            </div>
            <div className="single-testimonial">
              <div className="testimonials-wrapper">
                <h4>
                  Trade School is the best platform to learn about stock market
                  from basic to advanced. The best part about trade school is
                  they are providing
                  <span className="moretext">
                    global platform with practical. All the concepts are
                    explained really well. Thank you trade school.
                  </span>
                  <a className="moreless-button">read more</a>
                </h4>
                <div className="testimonials-blob"></div>
                <div className="testimonials-img">
                  <img
                    src="/assets/img/testimonials/parag.png"
                    className="img-100"
                  />
                </div>
                <div className="testimonials-person-info">
                  <p>
                    <b>Parag Gugale</b>
                    <br />
                    Bharati Vidyapeeth, Kolhapur
                  </p>
                </div>
              </div>
            </div>

            <div className="single-testimonial">
              <div className="testimonials-wrapper">
                <h4>
                  Trade school is the best course in Nagpur. We get more
                  knowledge about trading, and investment, Indian market
                  updates. Thank you trade school
                </h4>
                <div className="testimonials-blob"></div>
                <div className="testimonials-img ">
                  <img
                    src="/assets/img/testimonials/sukhbir.png"
                    className="img-100"
                  />
                </div>
                <div className="testimonials-person-info">
                  <p>
                    <b>Sukhbir kaur matharu</b>
                    <br />
                    Dayanand Arya Kanya Mahavidyalaya, Nagpur
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Testimonials;
