import React, { useEffect } from "react";

import Footer from "components/Footer";
import Header from "components/Header";
import FinancialLiteracy from "components/course_descriptions/FinancialLiteracy";
import { Link, useParams } from "react-router-dom";
import MutualFunds from "components/course_descriptions/MutualFunds";
import EquityDerivatives from "components/course_descriptions/EquityDerivatives";
import DigitalMarketing from "components/course_descriptions/DigitalMarketing";
import ResearchAnalysis from "components/course_descriptions/ResearchAnalysis";
import LetsStartUp from "components/course_descriptions/LetsStartUp";
import DataScience from "components/course_descriptions/DataScience";
import CyberSecurity from "components/course_descriptions/CyberSecurity";
import BigData from "components/course_descriptions/BigData";
import DataVisualization from "components/course_descriptions/DataVisualization";
import PythonProgramming from "components/course_descriptions/PythonProgramming";
import DronePilot from "components/course_descriptions/DronePilot";

const CourseDetails = () => {
  var { slug } = useParams();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Header />
      {slug == "financial-literacy" && <FinancialLiteracy />}
      {slug == "mutual-funds" && <MutualFunds />}
      {slug == "equity-derivatives" && <EquityDerivatives />}
      {slug == "research-analysis" && <ResearchAnalysis />}
      {slug == "lets-start-up" && <LetsStartUp />}
      {slug == "data-science" && <DataScience />}
      {slug == "cyber-security" && <CyberSecurity />}
      {slug == "big-data" && <BigData />}
      {slug == "digital-marketing" && <DigitalMarketing />}
      {slug == "data-visualization" && <DataVisualization />}
      {slug == "python-programming" && <PythonProgramming />}
      {slug == "drone-pilot" && <DronePilot />}

      <div className="d-flex justify-content-center mb-4">
        <a
          href="https://tschool.page.link/app"
          target="_blank"
          className="cta btn-get-started scrollto mx-4 mb-3"
        >
          Download the App Now!
        </a>
      </div>
      <Footer />
    </>
  );
};
export default CourseDetails;
