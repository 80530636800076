import React from "react";
import { Link } from "react-router-dom";

const FinancialLiteracy = () => {
  return (
    <>
      <section className="breadcrumbs">
        <div className="container">
          <div className="d-flex justify-content-between align-items-center">
            <ol>
              <li>
                <Link to="/">Home</Link>
              </li>
              <li>
                <Link to="/#courses">Courses</Link>
              </li>
              <li>Drone Pilot</li>
            </ol>
          </div>
        </div>
      </section>
      <section id="portfolio-details" className="course-details">
        <div className="container">
          <h1>Drone Pilot</h1>
          <br />
          <div className="row">
            <img
              className="course-details-image col-md-4"
              src="/assets/img/courses/12.png"
              alt="portfolio-img"
            />
            <div className="course-details-wrapper col-md-6">
              <p>
                Begin a game-changing career using dynamic drone technology.
                Drones have evolved from a military innovation to an exciting
                hobby to a technology that is transforming commercial industries
                in recent years — and future opportunities in the field are
                limitless. Our Course will provide you - Ground training
                Simulator flying One on one practical flying Solo flying
                Instrument flying Exposure to various emergency procedure
              </p>
              <br />
              <h6>Price:</h6>
              <p>60000 + GST</p>
              <h6>Duration:</h6>
              <p>5 to 10 Days</p>
            </div>
          </div>
          <div className="course-syllabus">
            <h6>Course Eligibility:</h6>
            <ol>
              <li>10th pass with english.</li>
              <li> Minimum 18 years of age.</li>
              <li>Duration of course is 5 to 10 days.</li>
              <li>Passport</li>
            </ol>
          </div>
        </div>
      </section>
    </>
  );
};
export default FinancialLiteracy;
