import React from "react";
import { Link } from "react-router-dom";

const FinancialLiteracy = () => {
  return (
    <>
      <section className="breadcrumbs">
        <div className="container">
          <div className="d-flex justify-content-between align-items-center">
            <ol>
              <li>
                <Link to="/">Home</Link>
              </li>
              <li>
                <Link to="/#courses">Courses</Link>
              </li>
              <li>Python Programming</li>
            </ol>
          </div>
        </div>
      </section>
      <section id="portfolio-details" className="course-details">
        <div className="container">
          <h1>Python Programming</h1>
          <br />
          <div className="row">
            <img
              className="course-details-image col-md-4"
              src="/assets/img/courses/11.png"
              alt="portfolio-img"
            />
            <div className="course-details-wrapper col-md-6">
              <p>
                Trade School's Python program has been meticulously created to
                provide the learner a solid foundation in the learning and usage
                of the language. Through the program, we intend to develop your
                skills starting from beginner level to an advanced level where
                you would be in a strong position to use Python Programming to
                implement mathematical problems / real-world scenarios into a
                highly usable, practical, and fundamentally strong Python code.
                Of course, you would need to apply your knowledge to projects to
                extend and solidify your proficiency, with which we will hand
                hold you, ensuring a smooth learning curve. This is a 2.5 -month
                course with 5 modules which is delivered by a faculty panel and
                senior professionals from the field of analytics in 80+ hours. A
                combination of Cases, Exercises, Lectures, 3+ Industry-based
                projects, 1 full-fledged capstone project, and Hands-on
                Practice, using analytics tools. The program also includes
                business talks from companies and business professionals, giving
                insight in their industry segment, opportunities, expectations,
                new trends, interview tips and adaption required in current
                times. Interactive Development Environments Anaconda (Jupyter
                Notebook ,Pycharm, Spyder) Python | Jupyter | Pandas | NumPy |
                SciPy | MS Excel
              </p>

              <h6>Price:</h6>
              <p>35000 + GST</p>
              <h6>Duration:</h6>
              <p>2.5 Months - 80+ Hours</p>
            </div>
          </div>

          <div className="course-syllabus">
            <h6>Course Syllabus :</h6>
            <ol>
              <li>Introduction to Data Science</li>
              <li>Re-visiting Mathematics</li>
              <li>Python for Data Sciences</li>
              <li>Introduction to Machine Learning</li>
              <li>Data Science Capstone Project</li>
            </ol>
          </div>
        </div>
      </section>
    </>
  );
};
export default FinancialLiteracy;
