import React, { useEffect } from "react";

const ClientSlider = () => {
  useEffect(() => {
    loadCoursal();
  });

  const loadCoursal = () => {
    $(".brand-carousel").owlCarousel({
      loop: true,
      margin: 10,
      autoplay: true,
      autoplayTimeout: 1500,
      responsive: {
        0: {
          items: 1,
        },
        600: {
          items: 3,
        },
        1000: {
          items: 5,
        },
      },
    });
  };
  return (
    <>
      <section id="clients" className=" section-bg">
        <div className="container">
          <div className="section-title">
            <h2 data-aos="fade-in">Our Clients</h2>
            <span data-aos="fade-in"></span>
          </div>
          <div className="brand-carousel section-padding owl-carousel">
            <div className="client-slider-img-wrapper">
              <img
                src="/assets/img/clients/rtmnu.jpg"
                alt="Rashtrasant University"
              />
              <div className="owl-name">
                Rashtrasant Tukadoji Maharaj Nagpur University
              </div>
            </div>
            <div className="client-slider-img-wrapper">
              <img src="/assets/img/clients/dolle.jpg" alt="DOLLE" />
              <div className="owl-name">
                Department of Lifelong Learning and Extension
              </div>
            </div>

            <div className="client-slider-img-wrapper">
              <img src="/assets/img/clients/rdgcfw.jpg" alt="RDG College" />
              <div className="owl-name">RDG College for Women, Akola</div>
            </div>
            <div className="client-slider-img-wrapper">
              <img
                src="/assets/img/clients/sssmaacc.jpg"
                alt="Sahyadri College"
              />
              <div className="owl-name">
                Sahyadri Shikshan Seva Mandal's Arts and Commerce College
              </div>
            </div>
            <div className="client-slider-img-wrapper">
              <img
                src="/assets/img/clients/smmpswc.jpg"
                alt="M.P.Shah women college"
              />
              <div className="owl-name">
                Smt.Maniben M.P.Shah Women's College
              </div>
            </div>
            <div className="client-slider-img-wrapper">
              <img src="/assets/img/clients/gscaack.jpg" alt="G.S College" />
              <div className="owl-name">
                G.S College of Arts and Commerce, Khamgaon
              </div>
            </div>
            <div className="client-slider-img-wrapper">
              <img src="/assets/img/clients/tcosac.jpg" alt="Thakur College" />
              <div className="owl-name">
                Thakur College of Science and Commerce
              </div>
            </div>
            <div className="client-slider-img-wrapper">
              <img
                src="/assets/img/clients/aic.jpg"
                alt="Anjuman Islam College"
              />
              <div className="owl-name">Anjuman Islam College</div>
            </div>
            <div className="client-slider-img-wrapper">
              <img
                src="/assets/img/clients/nrkcoaac.jpg"
                alt="Narayan Rao kale College"
              />
              <div className="owl-name">
                Narayan Rao Kale College of Arts and Commerce
              </div>
            </div>

            <div className="client-slider-img-wrapper">
              <img
                src="/assets/img/clients/sbcc.jpg"
                alt="Shree Binzani City College"
              />
              <div className="owl-name">Shree Binzani City College</div>
            </div>
            <div className="client-slider-img-wrapper">
              <img
                src="/assets/img/clients/ladc.jpg"
                alt="Lady Amritbai Daga college"
              />
              <div className="owl-name">Lady Amritbai Daga college</div>
            </div>
            <div className="client-slider-img-wrapper">
              <img
                src="/assets/img/clients/dakm.jpg"
                alt="Dayanand Arya Kanya Mahavidhyaya"
              />
              <div className="owl-name">Dayanand Arya Kanya Mahavidhyaya</div>
            </div>
            <div className="client-slider-img-wrapper">
              <img
                src="/assets/img/clients/jit.png"
                alt="Jhulelal Institute of Technology"
              />
              <div className="owl-name">Jhulelal Institute of Technology</div>
            </div>
            <div className="client-slider-img-wrapper">
              <img
                src="/assets/img/clients/dac.png"
                alt="Dr. Ambedkar College"
              />
              <div className="owl-name">Dr. Ambedkar College</div>
            </div>
            <div className="client-slider-img-wrapper">
              <img
                src="/assets/img/clients/dgicm.jpg"
                alt=" Dhananjay Gadgil Institute"
              />
              <div className="owl-name">
                Dhananjay Gadgil Institute of Cooperative Management
              </div>
            </div>

            <div className="client-slider-img-wrapper">
              <img
                src="/assets/img/clients/srpce.jpg"
                alt="Smt. Radhikatai Pandav College"
              />
              <div className="owl-name">
                Smt. Radhikatai Pandav College of Engineering
              </div>
            </div>
            <div className="client-slider-img-wrapper">
              <img
                src="/assets/img/clients/cicbms.png"
                alt=" Central India College of Business Management & Studies"
              />
              <div className="owl-name">
                Central India College of Business Management & Studies
              </div>
            </div>
            <div className="client-slider-img-wrapper">
              <img
                src="/assets/img/clients/tim.jpg"
                alt="Tirpude Institute of Management"
              />
              <div className="owl-name">Tirpude Institute of Management</div>
            </div>
            <div className="client-slider-img-wrapper">
              <img
                src="/assets/img/clients/dbm.jpg"
                alt="Department of Business Management"
              />
              <div className="owl-name">Department of Business Management</div>
            </div>
            <div className="client-slider-img-wrapper">
              <img
                src="/assets/img/clients/kcbhbedc.jpg"
                alt="KC Bajaj Hindi B.ED College"
              />
              <div className="owl-name">KC Bajaj Hindi B.ED College</div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default ClientSlider;
