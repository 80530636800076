import React, { useEffect } from "react";

const EventSlider = () => {
  useEffect(() => {
    loadCoursal();
  });

  const loadCoursal = () => {
    $(".team-carousel").owlCarousel({
      loop: true,
      autoplay: true,
      autoplayTimeout: 3500,
      responsive: {
        0: {
          items: 1,
        },
        600: {
          items: 2,
        },
        1000: {
          items: 3,
        },
      },
    });
  };
  return (
    <>
      <section id="clients" className=" section-bg">
        <div className="container">
          <div className="section-title">
            <h2 data-aos="fade-in">Past Events</h2>
            <span data-aos="fade-in"></span>
          </div>
          <div className="team-carousel owl-carousel">
            <div className="owl-item-event">
              <img src="/assets/img/gallery/1.png" alt="team" />
            </div>
            <div className="owl-item-event ">
              <img src="/assets/img/gallery/2.png" alt="team-photo" />
            </div>
            <div className="owl-item-event">
              <img src="/assets/img/gallery/3.png" alt="team-photo" />
            </div>
            <div className="owl-item-event">
              <img src="/assets/img/gallery/4.png" alt="team-photo" />
            </div>
            <div className="owl-item-event">
              <img src="/assets/img/gallery/5.png" alt="team-photo" />
            </div>
            <div className="owl-item-event">
              <img src="/assets/img/gallery/6.png" alt="team-photo" />
            </div>
            <div className="owl-item-event">
              <img src="/assets/img/gallery/7.png" alt="team-photo" />
            </div>
            <div className="owl-item-event">
              <img src="/assets/img/gallery/8.png" alt="team-photo" />
            </div>
            <div className="owl-item-event">
              <img src="/assets/img/gallery/9.png" alt="team-photo" />
            </div>
            <div className="owl-item-event">
              <img src="/assets/img/gallery/10.png" alt="team-photo" />
            </div>
          </div>
        </div>
        <div className=" d-flex align-item-center justify-content-center mt-5">
          <a href="/gallery" className="btn-event">
            View More >
          </a>
        </div>
      </section>
    </>
  );
};

export default EventSlider;
