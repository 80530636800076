import React from "react";
import { Link } from "react-router-dom";

const DataScience = () => {
  return (
    <>
      <section className="breadcrumbs">
        <div className="container">
          <div className="d-flex justify-content-between align-items-center">
            <ol>
              <li>
                <Link to="/">Home</Link>
              </li>
              <li>
                <Link to="/#courses">Courses</Link>
              </li>
              <li>Data Science</li>
            </ol>
          </div>
        </div>
      </section>
      <section id="portfolio-details" className="course-details">
        <div className="container">
          <h1>Data Science</h1>
          <br />
          <div className="row">
            <img
              className="course-details-image col-md-4"
              src="/assets/img/courses/7.png"
              alt="portfolio-img"
            />
            <div className="course-details-wrapper col-md-6">
              <p>
                <h6>This Course provides:</h6>
                <ul>
                  <li>
                    As every big company is generating Data at an unstoppable
                    rate, Data Science skills are in huge demand. Data Science
                    has become a promising and in-demand career path for skilled
                    professionals
                  </li>
                  <li>
                    This is an intensive Data Science curriculum covering all
                    the techniques and most of the in-demand tools to equip our
                    students to become professional Data Scientists.
                  </li>
                  <li>
                    {" "}
                    This program is of 8-months and designed for 315+ hours and
                    Instructor-Led Virtual Classroom Training sessions with 8
                    Modules and delivered by a faculty panel and senior
                    professionals from the field of analytics. A combination of
                    Cases, Exercises, Lectures, 16+ Industry-based projects, and
                    Hands-on Practice, using analytics tools and 1 full-fledged
                    Capstone Project.
                  </li>

                  <li>
                    16 important Data Science tools - Python | Jupyter | Panda |
                    Numpy | Scipy | Tableau | PowerBI | Excel | TensorFlow |
                    Keras | Hadoop | Hbase | Hive | PIG | Sqoop | Spark
                  </li>
                </ul>
              </p>
              <br />
              <h6>Price:</h6>
              <p>75000 + GST</p>
              <h6>Duration:</h6>
              <p>8 Months - 315+ Hours</p>
            </div>
          </div>
          <div className="course-syllabus">
            <h6>Following Topics will be covered in this module – </h6>
            <ol>
              <li>Introduction to Data Science</li>
              <li>Statistics for Data Science </li>
              <li>Python Tool for Data Science</li>
              <li>Data Exploring in Data Science</li>
              <li>Machine Learning/Artificial Intelligence for Data Science</li>
              <li>Data Visualization with Tableau</li>
              <li>Big Data & Hadoop</li>
              <li>Data Science Capstone Project</li>
            </ol>
          </div>
        </div>
      </section>
    </>
  );
};
export default DataScience;
