import React from "react";
import { Link } from "react-router-dom";

const FinancialLiteracy = () => {
  return (
    <>
      <section className="breadcrumbs">
        <div className="container">
          <div className="d-flex justify-content-between align-items-center">
            <ol>
              <li>
                <Link to="/">Home</Link>
              </li>
              <li>
                <Link to="/#courses">Courses</Link>
              </li>
              <li>Big Data</li>
            </ol>
          </div>
        </div>
      </section>
      <section id="portfolio-details" className="course-details">
        <div className="container">
          <h1>Big Data</h1>
          <br />
          <div className="row">
            <img
              className="course-details-image col-md-4"
              src="/assets/img/courses/9.png"
              alt="portfolio-img"
            />
            <div className="course-details-wrapper col-md-6">
              <p>
                This course intensively covers Data Science with Big Data and
                Hadoop. In this course, we will introduce Big Data, 5 Vs of Big
                Data, Stages of Big Data Analytics, Apache Hadoop, Hadoop
                MapReduce, HBase, Hive, PIG, Sqoop, and Spark. At the end of the
                course, our students will be able to handle various problems in
                the Data Science world and prepare for demanding jobs. This
                Interactive Sessions with 3 modules delivered by a faculty panel
                and senior professionals from the field of analytics in 90+
                hours. A combination of Cases, Exercises, Lectures, 3+
                Industry-based Projects, 1 full-fledged capstone project and
                Hands-on Practice using analytics tools. Business talks from
                companies and business professionals, giving insight into their
                industry segment, opportunities, expectations, new trends,
                interview tips, and adaption required in current times. 6
                Important Big Data tools - Hadoop | HBase | Hive | PIG | Sqoop |
                Spark
              </p>
              <br />
              <h6>Price:</h6>
              <p>40000 + GST</p>
              <h6>Duration:</h6>
              <p>3 Months - 90+ Hours</p>
            </div>
          </div>
          <div className="course-syllabus">
            <h6>Course Syllabus :</h6>
            <ol>
              <li>Introduction to Data Science</li>
              <li>Big Data and Hadoop</li>
              <li>Data Science Capstone Project</li>
            </ol>
          </div>
        </div>
      </section>
    </>
  );
};
export default FinancialLiteracy;
