import React from "react";
import { Link } from "react-router-dom";

const FinancialLiteracy = () => {
  return (
    <>
      <section className="breadcrumbs">
        <div className="container">
          <div className="d-flex justify-content-between align-items-center">
            <ol>
              <li>
                <Link to="/">Home</Link>
              </li>
              <li>
                <Link to="/#courses">Courses</Link>
              </li>
              <li>Mutual Funds </li>
            </ol>
          </div>
        </div>
      </section>
      <section id="portfolio-details" className="course-details">
        <div className="container">
          <h1>Mutual Funds</h1>
          <br />
          <div className="row">
            <img
              className="course-details-image col-md-4"
              src="/assets/img/courses/2.png"
              alt="portfolio-img"
            />
            <div className="course-details-wrapper col-md-6">
              <p>
                The course will help you develop the knowledge on the basics of
                mutual funds, their structure, different kind of mutual funds
                and their features. Understand how the distribution of these
                work in the marketplace, how the evaluation works. Get in depth
                knowledge of Legalities. Understand the approach to investing in
                mutual funds through financial planning.
              </p>
              <br />
              <h6>Price:</h6>
              <p>2500 + GST</p>
              <h6>Duration:</h6>
              <p>1 Month - 30 Hours</p>
            </div>
          </div>
          <div className="course-syllabus">
            <h6>Course Syllabus :</h6>
            <ol>
              <li>Investment Landscape</li>
              <li>Concept and role of Mutual Fund</li>
              <li>legal Structure of Mutual Fund in India</li>
              <li>Legal and Regulatory Framework</li>
              <li>Scheme Related Information</li>
              <li>Fund Distributin and Channel managemnet Practices</li>
              <li>Net Asset Value,Total Expense Ratio And Pricing of Units</li>
              <li>Government Schemes on Investment</li>
              <li>Taxation</li>
              <li>Investor Services</li>
              <li>Risk ,Return and Performance of Funds</li>
              <li>Mutual Fund Scheme Performance</li>
              <li>Mutual Fund Scheme Selection</li>
            </ol>
          </div>
        </div>
      </section>
    </>
  );
};
export default FinancialLiteracy;
