import React from "react";
import { Link } from "react-router-dom";

const LetsStartup = () => {
  return (
    <>
      <section className="breadcrumbs">
        <div className="container">
          <div className="d-flex justify-content-between align-items-center">
            <ol>
              <li>
                <Link to="/">Home</Link>
              </li>
              <li>
                <Link to="/#courses">Courses</Link>
              </li>
              <li>Let's Startup</li>
            </ol>
          </div>
        </div>
      </section>
      <section id="portfolio-details" className="course-details">
        <div className="container">
          <h1>Let's Startup</h1>
          <br />
          <div className="row">
            <img
              className="course-details-image col-md-4"
              src="/assets/img/courses/6.png"
              alt="portfolio-img"
            />
            <div className="course-details-wrapper col-md-6">
              <p>
                <h6>This Course provides:</h6>
                <ul>
                  <li>Practical based Learning</li>
                  <li>
                    A deep understanding of the core strategic choices facing
                    startup innovators.
                  </li>
                  <li>
                    {" "}
                    A synthetic framework for the development and implementation
                    of startup strategy in dynamic environments.
                  </li>
                  <li>
                    Key insights into skills and behaviors required to scale
                    those ventures over time.
                  </li>
                  <li>
                    A methodology for evaluating the factors that are most
                    important for successfully scaling an entrepreneurial
                    venture
                  </li>
                  <li>A knowledge about Government Schemes for start-ups</li>
                  <li>Knowledge on Pitching & Funding.</li>
                </ul>
              </p>
              <h6>Price:</h6>
              <p>3500 + GST</p>
              <h6>Duration:</h6>
              <p>1.5 Month - 45 Hours</p>
            </div>
          </div>
          <div className="course-syllabus">
            <h4>Course Syllabus: </h4>
            <ol>
              <li>Are you an Entrepreneur </li>
              <li>What is startup </li>
              <li>Idea building and assessing </li>
              <li>Plan your business </li>
              <li>Know your legal compliance </li>
              <li>Govt. scheme on startup </li>
              <li>Pitching and funding </li>
            </ol>
          </div>
          <br />
          <div className="course-syllabus">
            <h4>Practicals</h4>
            <ol>
              <li>Are you an Entrepreneur </li>
              <li>What is startup </li>
              <li>Idea building and assessing </li>
              <li>Plan your business </li>
              <li>Know your legal compliance </li>
              <li>Govt. scheme on startup </li>
              <li>Pitching and funding </li>
            </ol>
          </div>
        </div>
      </section>
    </>
  );
};
export default LetsStartup;
