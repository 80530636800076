import Footer from "components/Footer";
import Header from "components/Header";
import About from "pages/home/About";
import ClientSlider from "pages/home/ClientSilder";
import Contact from "pages/home/Contact";
import Courses from "pages/home/Courses";
import Features from "pages/home/Features";
import Hero from "pages/home/Hero";
import Team from "pages/home/Team";
import React from "react";
import Testimonials from "pages/home/Testimonials";
import EventSlider from "pages/home/EventSlider";

export const Home = () => {
  return (
    <>
      <Header />
      <Hero />
      <About />
      <Courses />
      <Features />
      <ClientSlider />
      <EventSlider />
      <Team />
      <Testimonials />
      <Contact />
      <Footer />
    </>
  );
};
export default Home;
