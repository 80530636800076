import React from "react";
import { Link } from "react-router-dom";

const FinancialLiteracy = () => {
  return (
    <>
      <section className="breadcrumbs">
        <div className="container">
          <div className="d-flex justify-content-between align-items-center">
            <ol>
              <li>
                <Link to="/">Home</Link>
              </li>
              <li>
                <Link to="/#courses">Courses</Link>
              </li>
              <li>Financial Literacy </li>
            </ol>
          </div>
        </div>
      </section>
      <section id="portfolio-details" className="course-details">
        <div className="container">
          <h1>Financial Literacy </h1>
          <br />
          <div className="row">
            <img
              className="course-details-image col-md-4"
              src="/assets/img/courses/1.png"
              alt="portfolio-img"
            />
            <div className="course-details-wrapper col-md-6">
              <p>
                This is basic level program for those who wish to either begin a
                career in the Financial Markets in India & Globally. The course
                is structured to help understand the basic concepts, basic terms
                & jargons used,different avenues of investment, the primary &
                secondary market etc.
              </p>

              <br />
              <h6>Price:</h6>
              <p>3000 + GST</p>
              <h6>Duration:</h6>
              <p>2 Months - 60 Hours</p>
            </div>
          </div>
          <div className="course-syllabus">
            <h4>Beginners Module</h4>

            <h6>Course Syllabus :</h6>
            <ol>
              <li> Financial Literacy</li>
              <li>Financial Planning & Budgeting</li>
              <li>The need to Invest in Securities Market</li>
              <li>How & Where to invest in securities market?</li>
              <li>Mutual Funds</li>
              <li>Banking Products</li>
              <li>REITs</li>
              <li>Government Schemes on Investment</li>
              <li>Tax Planning</li>
              <li>Retirement Planning</li>
              <li>Caution against Scams in Securities Market</li>
            </ol>
          </div>
          <br />
          <div className="course-syllabus">
            <h4>Fundamentals Analysis Module</h4>
            <p>
              In this module Students will learn how to analyze Balance Sheet,
              Profit & loss Statement, Cash Flow, Ratio Analysis of stocks using
              fundamental tools (Annual Reports) of companies and by using
              formulas. An appropriate analysis forms the basis of successful
              investment decisions. This module aims at providing a basic
              insight about Fundamental Analysis and various valuation
              methodologies used.
            </p>
            <h6>Course Syllabus :</h6>
            <ol>
              <li>Introduction to Fundamental Analysis</li>
              <li>How to do Fundamental Analysis?</li>
              <li>Company Analysis</li>
              <li>Ratio Analysis</li>
              <li>Corporate Actions</li>
              <li>Case Study(Practical)</li>
            </ol>
          </div>
          <br />
          <div className="course-syllabus">
            <h4>Technical Analysis Module</h4>
            <p>
              This module has been prepared with a view to provide a
              comprehensive and in depth knowledge about Technical Analysis.
              Students will learn (Theory + Practical) of various Technical
              Analysis tools like MACD, RSI, Japanese Candle sticks, Dow Theory,
              etc.
            </p>
            <h6>Course Syllabus :</h6>
            <ol>
              <li>Introduction to Technical Analysis</li>
              <li>Trend & Trendlines</li>
              <li>Support & Resistance Levels</li>
              <li>Understanding Multiple Time Frame Analysis</li>
              <li>Candlesticks & Psychology</li>
              <li>Candlestick Patterns</li>
              <li>Single Candlestick Pattern</li>
              <li>Double Candlestick Pattern</li>
              <li>Triple Candlestick Pattern</li>
              <li>Chart Pattern</li>
              <li>Assignment Discussion</li>
              <li>Volume Analysis</li>
              <li>Gaps</li>
              <li>Intraday Trading Setups</li>
              <li>Risk Management</li>
            </ol>
          </div>
        </div>
      </section>
    </>
  );
};
export default FinancialLiteracy;
