import React from "react";
import "../index.css";

const Footer = () => {
  return (
    <>
      <footer id="footer">
        <div className="footer-top">
          <div className="container">
            <div className="row  d-flex  justify-content-center">
              <a href="#" className="navbar-brand flex-column">
                <img src="/assets/img/ts_long_white.png" alt="TradeSchool" />
              </a>
              <span className="text-center" style={{ fontStyle: "italic" }}>
                Learn to Earn, Anytime, Anywhere.
              </span>
            </div>

            <div className="social-links">
              <a
                href="https://facebook.com/tradeschool.co.in/"
                target="_blank"
                className="facebook"
              >
                <i className="bx bxl-facebook"></i>
              </a>
              <a
                href="https://www.instagram.com/tradeschool.co.in/"
                target="_blank"
                className="instagram"
              >
                <i className="bx bxl-instagram"></i>
              </a>
              <a
                href="https://www.youtube.com/@tradeschool6675"
                target="_blank"
                className="youtube"
              >
                <i className="bx bxl-youtube"></i>
              </a>
              <a
                href="https://www.linkedin.com/company/tradeschool-co-in/"
                target="_blank"
                className="linkedin"
              >
                <i className="bx bxl-linkedin"></i>
              </a>
            </div>
          </div>
        </div>
        <div className="container footer-bottom clearfix">
          <div className="copyright">
            &copy; Copyright &nbsp;
            <strong>
              <span>TradeSchool</span>
            </strong>
            . All Rights Reserved
          </div>
          <div className="credits">
            Designed By
            <a target="_blank" href="https://www.kishul.com/">
              Kishul Solutions
            </a>
          </div>
        </div>
      </footer>
      <a
        href="#"
        className="back-to-top d-flex align-items-center justify-content-center"
      >
        <i className="bi bi-arrow-up-short"></i>
      </a>
    </>
  );
};

export default Footer;
