import React, { useState } from "react";
import { Link } from "react-router-dom";
import "utils/main.js";

const Header = (ref) => {
  return (
    <>
      <header id="header">
        <div className="container d-flex align-items-center justify-content-between">
          <div className="logo">
            <a to="/">
              <img
                src="/assets/img/ts_long_white.png"
                alt="tradeschool"
                className="img-fluid"
              />
            </a>
          </div>

          <nav id="navbar" className="navbar">
            <ul>
              <li>
                <a className="nav-link scrollto active" href="/">
                  Home
                </a>
              </li>
              <li>
                <a
                  className="nav-link scrollto"
                  // onClick={() => scrollDown(About)}
                  href="/#about"
                >
                  About Us
                </a>
              </li>
              <li>
                <a
                  className="nav-link scrollto"
                  // onClick={() => scrollDown(features)}
                  href="/#features"
                >
                  Features
                </a>
              </li>
              <li>
                <a className="nav-link scrollto" href="/#team">
                  Team
                </a>
              </li>
              <li>
                <a className="nav-link scrollto" href="/blogs">
                  Blog
                </a>
              </li>
              <li>
                <a className="nav-link scrollto" href="/#contact">
                  Contact Us
                </a>
              </li>
            </ul>

            <i className="bi bi-list mobile-nav-toggle"></i>
          </nav>
        </div>
     
      </header>
    </>
  );
};

export default Header;
