import Footer from 'components/Footer'
import Header from 'components/Header'
import React from 'react'

const Gallery = () => {
  return (
    <>
    <Header/>
    <div>
      <div className="container my-5 ">
        <div className="row event-images">
            <div className="col-md-4 col-sm-12 mb-5 text-center">
            <img  classname="img-fluid" src="/assets/img/gallery/1.png" alt="team" />
            <div className='event-name mt-2'>Dr. Ambedkar College, Nagpur</div>
            </div>
            <div className="col-md-4 col-sm-12 mb-5 text-center">
            <img  classname="img-fluid"src="/assets/img/gallery/2.png" alt="team" />
            <div className='event-name mt-2'>Department og Life Learning and Extension</div>
            </div>
            <div className="col-md-4 col-sm-12 mb-5 text-center">
            <img  classname="img-fluid" src="/assets/img/gallery/3.png" alt="team" />
            <div className='event-name mt-2'>SNDT Women's University</div>
            </div>
        </div>
        <div className="row event-images">
            <div className="col-md-4 col-sm-12 mb-5 text-center">
            <img  classname="img-fluid" src="/assets/img/gallery/4.png" alt="team" />
            <div className='event-name mt-2'>Dayanand Arya Kanya Vidhyalaya,Nagpur</div>
            </div>
            <div className="col-md-4 col-sm-12 mb-5 text-center">
            <img  classname="img-fluid" src="/assets/img/gallery/5.png" alt="team" />
            <div className='event-name mt-2'>Dayanand Arya Kanya Vidhyalaya,Nagpur</div>
            </div>
            <div className="col-md-4 col-sm-12 mb-5 text-center">
            <img  classname="img-fluid" src="/assets/img/gallery/6.png" alt="team" />
            <div className='event-name mt-2'>Nagpur Institute of Technology</div>
            </div>
        </div>
        <div className="row event-images">
            <div className="col-md-4 col-sm-12 mb-5 text-center">
            <img  classname="img-fluid" src="/assets/img/gallery/7.png" alt="team" />
            <div className='event-name mt-2'>Financial Literacy Workshop</div>
            </div>
            <div className="col-md-4 col-sm-12 mb-5 text-center">
            <img  classname="img-fluid" src="/assets/img/gallery/8.png" alt="team" />
            <div className='event-name mt-2'>Manzile Financial Wisdom for women</div>
            </div>
            <div className="col-md-4 col-sm-12 mb-5 text-center">
            <img  classname="img-fluid py-2" src="/assets/img/gallery/9.png" alt="team" />
            <div className='event-name mt-2'>Jito Women Empowerment Seminar</div>
            </div>
        </div>
        <div className="row event-images">
        <div className="col-md-4 offset-md-4 col-sm-12 mb-5 text-center">
            <img  classname="img-fluid my-2 " src="/assets/img/gallery/10.png" alt="team" />
            <div className='event-name mt-2'>News Paper Article</div>
            </div>
        </div>
      </div>
    </div>
    <Footer/>
    </>
  )
}

export default Gallery
