import React from "react";
import { Link } from "react-router-dom";

const FinancialLiteracy = () => {
  return (
    <>
      <section className="breadcrumbs">
        <div className="container">
          <div className="d-flex justify-content-between align-items-center">
            <ol>
              <li>
                <Link to="/">Home</Link>
              </li>
              <li>
                <Link to="/#courses">Courses</Link>
              </li>
              <li>Equity Derivatives </li>
            </ol>
          </div>
        </div>
      </section>
      <section id="portfolio-details" className="courses-details">
        <div className="container">
          <h1>Equity Derivatives</h1>
          <br />
          <div className="row">
            <img
              className="course-details-image col-md-4"
              src="/assets/img/courses/3.png"
              alt="portfolio-img"
            />
            <div className="course-details-wrapper col-md-6">
              <p>
                This Course will help you Understand the basics of Indian equity
                derivatives market how the derivatives market operates in India.
                Decode various trading strategies. Understand the operational
                mechanism related to equity derivatives market also the clearing
                settlement and Risk management.
              </p>
              <br />

              <h6>Price:</h6>
              <p> 2500 + GST</p>
              <h6>Duration:</h6>
              <p>1 Month - 30 Hours</p>
            </div>
          </div>

          <div className="course-syllabus">
            <h6>Course Syllabus :</h6>
            <ol>
              <li>Basics of Derivatives</li>
              <li>Understanding Index</li>
              <li>Introduction to Forward and Futures</li>
              <li>Introduction to Options</li>
              <li> Option Trading Strategies</li>
              <li> Introduction to Trading Systems</li>
              <li>Introduction to Clearing and Settlement System</li>
              <li> Legal and Regulatory Environment</li>
              <li>Accounting and Taxation</li>
              <li>Investor Services</li>
              <li>Sales Practices and Investors Protection Services</li>
            </ol>
          </div>
        </div>
      </section>
    </>
  );
};
export default FinancialLiteracy;
