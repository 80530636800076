import React from "react";
import { Link } from "react-router-dom";

const FinancialLiteracy = () => {
  return (
    <>
      <section className="breadcrumbs">
        <div className="container">
          <div className="d-flex justify-content-between align-items-center">
            <ol>
              <li>
                <Link to="/">Home</Link>
              </li>
              <li>
                <Link to="/#courses">Courses</Link>
              </li>
              <li>Data Visualization</li>
            </ol>
          </div>
        </div>
      </section>
      <section id="portfolio-details" className="course-details">
        <div className="container">
          <h1>Data Visualization</h1>
          <br />
          <div className="row">
            <img
              className="course-details-image col-md-4"
              src="/assets/img/courses/10.png"
              alt="portfolio-img"
            />
            <div className="course-details-wrapper col-md-6">
              <p>
                This course covers the fundamentals of Data Visualization using
                important and in-demand tool - Tableau / Power Bi. We introduce
                the importance of Data Visualization in an expanding market and
                equip our learners with the skills they need to handle complex
                Data Visualization requirements in different job roles and
                markets. This program is designed for 2 months, Instructor-led,
                Live and Interactive Sessions with 4 modules delivered by
                Industry mentors and senior professionals from the field of
                analytics in 60+ hours. It is a combination of Case Studies,
                Exercises, Lectures, 3+ Industry Based Projects, 1 full-fledged
                capstone project and Hands-on Practice using analytics tools.
                Business talks from companies and business professionals, giving
                insight into their industry segment, opportunities,
                expectations, new trends, interview tips, and adaption required
                in current times. Tools Covered Tableau | Power Bi | MS Excel
              </p>
              <br />
              <h6>Price:</h6>
              <p> 32000 + GST</p>
              <h6>Duration:</h6>
              <p>2 Months - 60+ Hours</p>
            </div>
          </div>
          <div className="course-syllabus">
            <h6>Course Syllabus :</h6>
            <ol>
              <li>Introduction to Data Science</li>
              <li>Mathematics for Data Science</li>
              <li>Data Visualization with Tableau / Power BI</li>
              <li>Data Science Capstone Project</li>
            </ol>
          </div>
        </div>
      </section>
    </>
  );
};
export default FinancialLiteracy;
