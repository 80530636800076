import React from "react";
import { Link } from "react-router-dom";

const FinancialLiteracy = () => {
  return (
    <>
      <section className="breadcrumbs">
        <div className="container">
          <div className="d-flex justify-content-between align-items-center">
            <ol>
              <li>
                <Link to="/">Home</Link>
              </li>
              <li>
                <Link to="/#courses">Courses</Link>
              </li>
              <li>Cyber Security</li>
            </ol>
          </div>
        </div>
      </section>
      <section id="portfolio-details" className="course-details">
        <div className="container">
          <h1>Cyber Security</h1>
          <br />
          <div className="row">
            <img
              className="course-details-image col-md-4"
              src="/assets/img/courses/8.png"
              alt="portfolio-img"
            />
            <div className="course-details-wrapper col-md-6">
              <p>
                In this course we provide, an in-depth understanding of Cyber
                Security phases, various attack vectors, and preventive
                countermeasures. It will teach you how hackers think and act
                maliciously so that you will be better equipped to set up your
                security infrastructure and defend against future attacks.
                Understanding system weaknesses and vulnerabilities help
                organisations strengthen their system security controls to
                minimise the risk of an incident. This program has been designed
                to incorporate a hands-on environment and systematic process
                across every Ethical Hacking domain and methodology, giving us
                the opportunity to work towards providing the required knowledge
                and skills needed to perform the job of an Ethical Hacker.
              </p>
              <br />
              <h6>Price:</h6>
              <p>75000 + GST</p>
              <h6>Duration:</h6>
              <p>8 Months - 315+ Hours</p>
            </div>
          </div>
          <div className="course-syllabus">
            <h6>Course Syllabus :</h6>
            <ol>
              <li>Ethical Hacking</li>
              <li>Penetration Testing</li>
              <li>Network Security </li>
              <li>Exploit Writing</li>
              <li>Cyber Security Capstone Project</li>
            </ol>
          </div>
        </div>
      </section>
    </>
  );
};
export default FinancialLiteracy;
