import React from "react";

const About = () => {
  return (
    <>
      <section id="about" className="about section-bg" ref={About}>
        <div className="container">
          <div className="row gy-4">
            <div className="image col-xl-5"></div>
            <div className="col-xl-7">
              <div className="content d-flex flex-column justify-content-center ps-0 ps-xl-4">
                <h3 data-aos="fade-in" data-aos-delay="100">
                  About Us
                </h3>
                <span data-aos="fade-in">
                  Tradeschool is an attempt by Eton Advisors Pvt Ltd for
                  providing financial skill through Video Scribe. This platform
                  is created for “Mastering Stock Market through Video Scribe”
                  This platform provides Theory + Practical application of
                  financial knowledge. This is an initiative by Tradeschool to
                  provide practical exposure in real time market.
                </span>

                <div className="row gy-4 mt-3">
                  <div className="col-md-6 icon-box" data-aos="fade-up">
                    <i className="bx bx-shield"></i>
                    <h4>
                      <a href="#">Mission</a>
                    </h4>
                    <span>
                      To teach financial literacy at grass root level using
                      technology and empower people with financial knowledge.
                    </span>
                  </div>
                  <div
                    className="col-md-6 icon-box"
                    data-aos="fade-up"
                    data-aos-delay="100"
                  >
                    <i className="bx bx-images"></i>
                    <h4>
                      <a href="#">Vision</a>
                    </h4>
                    <span>
                      To make everyone Financially Independent by providing
                      knowledge of Finance, Money, Stock Markets and
                      Investments.
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
     
      </section>
    </>
  );
};

export default About;
