import React from "react";

const Features = () => {
  return (
    <>
      <section id="features" className="features section-bg">
        <div className="container">
          <div className="section-title">
            <h2 data-aos="fade-in">Features</h2>
            <span data-aos="fade-in">
              TradeSchool intends to provide all the knowledge and tools under
              one roof to get you started with financial freedom in life.
            </span>
          </div>

          <div className="row content">
            <div className="col-md-5" data-aos="fade-right">
              <img
                src="/assets/img/online_courses.png"
                className="img-fluid"
                alt=""
              />
            </div>
            <div className="col-md-7 pt-4" data-aos="fade-left">
              <h3>Online Courses + Live Webinars</h3>
              <ul>
                <li>
                  <i className="bi bi-check"></i> Students can apply for
                  education scholarships from the TradeSchool application!
                </li>
                <li>
                  <i className="bi bi-check"></i> Learn Anytime, Anywhere with
                  our pre-recorded lectures
                </li>
                <li>
                  <i className="bi bi-check"></i> Along with existing videos,
                  stay updated with live webinars to clear all the doubts
                </li>
                <li>
                  <i className="bi bi-check"></i> A well designed e-book to
                  understand the financial markets in depth.
                </li>
                <li>
                  <i className="bi bi-check"></i> Test your knowledge by giving
                  the exam on the subject
                </li>
                <li>
                  <i className="bi bi-check"></i> Get a course completion
                  certificate.
                </li>
              </ul>
            </div>
          </div>

          <div className="row content">
            <div className="col-md-5 order-1 order-md-2" data-aos="fade-left">
              <img
                src="/assets/img/pan_application.png"
                className="img-fluid"
                alt=""
              />
            </div>
            <div
              className="col-md-7 pt-5 order-2 order-md-1"
              data-aos="fade-right"
            >
              <h3>Online PAN Application</h3>
              <ul>
                <li>
                  <i className="bi bi-check"></i> We are pioneers in delivering
                  PAN card via mobile application.
                </li>
                <li>
                  <i className="bi bi-check"></i> PAN card application filling
                  has ever been so simple.
                </li>
                <li>
                  <i className="bi bi-check"></i> Get a virtual copy of your PAN
                  within 6 hours on your email address.
                </li>
                <li>
                  <i className="bi bi-check"></i> Affordable prices
                </li>
              </ul>
            </div>
          </div>

          <div className="row content">
            <div className="col-md-5" data-aos="fade-right">
              <img
                src="/assets/img/student_scholarship.png"
                className="img-fluid"
                alt=""
              />
            </div>
            <div className="col-md-7 pt-5" data-aos="fade-left">
              <h3>Student Scholarship Portal</h3>
              <span>
                <ul>
                  <li>
                    <i className="bi bi-check"></i> Students can apply for
                    education scholarships from the TradeSchool application!
                  </li>
                  <li>
                    <i className="bi bi-check"></i> Tradeschool contributes by
                    supporting education of economically weaker sections of the
                    society.
                  </li>
                  <li>
                    <i className="bi bi-check"></i> A free signup form on the
                    portal can help ease your financial burden.
                  </li>
                </ul>
              </span>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Features;
