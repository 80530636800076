import React from "react";
import { Link } from "react-router-dom";

const FinancialLiteracy = () => {
  return (
    <>
      <section className="breadcrumbs">
        <div className="container">
          <div className="d-flex justify-content-between align-items-center">
            <ol>
              <li>
                <Link to="/">Home</Link>
              </li>
              <li>
                <Link to="/#courses">Courses</Link>
              </li>
              <li>Research Analysis</li>
            </ol>
          </div>
        </div>
      </section>
      <section id="portfolio-details" className="course-details">
        <div className="container">
          <h1>Research Analysis</h1>
          <br />
          <div className="row">
            <img
              className="course-details-image col-md-4"
              src="/assets/img/courses/14.png"
              alt="portfolio-img"
            />
            <div className="course-details-wrapper col-md-6">
              <p>
                Set the Foundation to understand the Indian Securities market
                and the various terminologies used in equity and debt markets.
                Understand the approach to Fundamental Research, Understand
                Industry Analysis, Company Analysis, Know the basic principles
                for micro and macro-economic analysis, Learn about the
                Fundamentals of Risk and Return, Valuation.
              </p>
              <br />
              <h6>Price:</h6>
              <p>2500 + GST</p>
              <h6>Duration:</h6>
              <p>1 Month - 30 Hours</p>
            </div>
          </div>
          <div className="course-syllabus">
            <h6>Course Syllabus :</h6>
            <ol>
              <li>Introduction to Research Analyst Profession</li>
              <li>Introduction to Financial Market</li>
              <li>Terminology In Equity and Debt Markets</li>
              <li>Fundamentals of Research</li>
              <li>Economic Analysis</li>
              <li>Industry Analysis</li>
              <li>Company Analysis - Quantative Dimensions</li>
              <li>Company Analysis - Quantitative Dimensions</li>
              <li>Corporate Actions</li>
              <li>Valuation Principles</li>
              <li>Fundamentals of Risk and Return</li>
              <li>Qualities of Good Research Report</li>
              <li>Legal and Regulatory Environment</li>
            </ol>
          </div>
        </div>
      </section>
    </>
  );
};
export default FinancialLiteracy;
