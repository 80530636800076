import Home from "./Home";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import CourseDetails from "pages/CourseDetails";
import NotFound from "pages/NotFound";
import Gallery from "pages/Gallery";
import Blogs from "pages/Blogs";

function App() {
  return (
    <Router>
      <Routes>
        <Route exact path="/" element={<Home />} />
        <Route exact path="/courses/:slug" element={<CourseDetails />} />
        <Route path="*" exact={true} element={<NotFound />} />
        <Route path="/gallery" element={<Gallery/>}/>
        <Route path="/blogs" element={<Blogs/>}/>
      </Routes>
    </Router>
  );
}

export default App;
